/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { Children, ReactDOM } from "react"
import PropTypes from "prop-types"
import  $ from '../../../node_modules/jquery';
import jQuery from 'jquery'
import "./lavalamp.css"
import document from '../../../node_modules/jquery'
import "./jqueryeasingmin"
import lap from "./jquerylavalampmin"
//import MyLoad from "./js"
// import PropTypes from "prop-types"
// import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"

class LavaLamp extends React.Component {

  componentDidMount() {
   
        
    $(document).ready(
      
      function(){
      (new lap($("#menu"))).lavaLamp
      ({
        fx: "backout",
        speed: 550
      })
      }
      );
    }

    componentWillMount(){
        
//         switch (title) {
//    case "": this.state.pos = 0 
//      break;
//    case "": this.state.pos = 1
//      break;
// default:
//         }

    }
  

//const LavaLamp = (props) => {
  
  render(){

const {pos, children, ...other} = this.props 


  return (
    <>
    <div id="topNav">
    <ul className ="lavaLamp" id="menu"> 
    {/* <li className =  { pos == "0" ? "current": "" }><Link   to = "/index.html" >Home</Link></li> 
        <li className =  { pos == "1" ? "current": "" }><Link   to = "/math/index.html" >Math</Link></li> 
        <li className =  { pos == "2" ? "current": "" } ><Link  to = "/game/index.html" >Game</Link></li> 
        <li className =  { pos == "3" ? "current": "" }><Link   to = "/code/index.html" >Code</Link></li> 
        <li className =  { pos == "4" ? "current": "" }><Link   to = "/reality/index.html" >Reality</Link></li> 
        <li className =  { pos == "5" ? "current": "" }><Link   to = "/about/index.html" >About</Link></li>  */}
         <li className =  { pos == "0" ? "current": "" }><a   href = "/index.html" >Home</a></li> 
        <li className =  { pos == "1" ? "current": "" }><a   href = "/math/index.html" >Math</a></li> 
        <li className =  { pos == "2" ? "current": "" } ><a  href = "/game/index.html" >Game</a></li> 
        <li className =  { pos == "3" ? "current": "" }><a   href = "/code/index.html" >Code</a></li> 
        <li className =  { pos == "4" ? "current": "" }><a   href = "/reality/index.html" >Reality</a></li> 
        <li className =  { pos == "5" ? "current": "" }><a   href = "/about/index.html" >About</a></li>
         {/* <li><Link className =  { pos == "0" ? "current": "" }  to = "/" >Home</Link></li>  
        <li><Link className =  { pos == "1" ? "current": "" }  to = "/math"  >Math</Link></li> 
        <li><Link className =  { pos == "2" ? "current": "" }   to = "/game" >Game</Link></li> 
        <li><Link className =  { pos == "3" ? "current": "" }  to = "/code" >Code</Link></li> 
        <li><Link className =  { pos == "4" ? "current": "" } to = "/reality" >Reality</Link></li> 
        <li><Link className =  { pos == "5" ? "current": "" } to = "/about" >About</Link></li> 
   */}
        {/* // <li><Link className = { pos == "0" ? "active": "noactive" }  to = "/" >Home</Link></li> 
        // <li><Link className = { pos == "1" ? "active": "noactive" }  to = "/about" >About</Link></li> 
        // <li><Link className = {  pos == "2" ? "active": "noactive" } to = "/page-2" >page2</Link></li> 
        // <li><Link className = {  pos == "3" ? "active": "noactive" } to = "/page3" >page3</Link></li> 
        */}
         </ul>
       </div>
       <div>{children}</div>       
          </>
    )
}
}

LavaLamp.propTypes = {
  children: PropTypes.node,
  pos: PropTypes.string,
}


//    }
export default LavaLamp
