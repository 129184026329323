import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import LavaLamp from "../components/lavalamp/LavaLamp"

const MathPage = () => (
 <><LavaLamp pos = "1"></LavaLamp>
  <Layout  style = {{backgroundColor: "#00000"}}>
    <SEO title="Math two" />
  
    <h1 >  Just Math 24 </h1>
	  <p> A 3d math playground </p>
	<br></br>
   <p>This math game has a HARD CORE: </p>
		<ul>
		  <p> You are given four numbers, pick them in ORDER that you can get 24 after calcuation. Otherwise just pass.</p>
		   
		      <p>  for example : <i>3 5 4 1</i></p>
		      <p> one order can be: <i>3 5 4 1</i>    is correct : 3 * (( 5 + 4) - 1) = 24</p>
		       <p> another order can be: <i> 3 1 4 5</i>  is not correct,  as you cannot list an equation like above.</p>
		       <p> Right? Wait a minute, actually you can:  3 + 1 + 4 * 5 = 24</p>
		
		       <p> Now let us try the next order <i> 3 4 1 5 </i> , can you? </p>
		
		       <p> If you cannot but you pick the numbers this way, <b>FAIL!</b></p>
		    
		      <p>  Otherwise if there is no way to get 24, just pass.</p>
	
		      <p>  1 1 1 1  -- pass, right?</p>
		
		      <p>  2 2 2 2  -- pass, right?</p>
		
		      <p>  3 3 3 3  -- pass, right?</p>
		
		      <p>  4 4 4 4  -- pass, right?</p>
		
		      <p>  If you don't know the answer, ask you friend by the Math24 in iMessage. </p>
		
		      <p> In summary as to the order, any reasonable way is accepted. After the result is revealed, it may surprise you with a way you miss. </p>
	</ul>
	   
  </Layout></>
)

export default MathPage
